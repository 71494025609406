import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom'
import { ArithmeticQuizPath, SpellingQuizPath, TicketMakerPath, WatchListPath } from './Constants';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const NavMenuLinks = () => {

  const location = useLocation();
  location.pathname.toLowerCase().includes(ArithmeticQuizPath)
  console.log(location.pathname);

  const getLinks = () => {
    if (location.pathname.toLowerCase().includes(ArithmeticQuizPath.toLowerCase())) {
      return (
        <>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to={`/${ArithmeticQuizPath}/Addition`}>Addition</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to={`/${ArithmeticQuizPath}/Subtraction`}>Subtraction</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to={`/${ArithmeticQuizPath}/Multiplication`}>Multiplication</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to={`/${ArithmeticQuizPath}/Division`}>Division</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to={`/${ArithmeticQuizPath}/Admin`}>Quiz Admin</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
          </NavItem>
        </>
      );
    } else if (location.pathname.toLowerCase().includes(WatchListPath.toLowerCase())) {
      return (
        <>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to={`/${WatchListPath}/`}>Watch Matches</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to={`/${WatchListPath}/WatchListIndex/`}>Edit Watch List</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
          </NavItem>
        </>
      );

    } else if (location.pathname.toLowerCase().includes(SpellingQuizPath.toLowerCase())) {
      return (
        <>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
          </NavItem>
        </>
      );

    }
    else if (location.pathname === '/') {
      return (
        <>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to={`/${ArithmeticQuizPath}`}>Arithmetic</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to={`/${SpellingQuizPath}/`}>Spelling</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to={`/${TicketMakerPath}/`}>Tickets</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} className="text-dark" to={`/${WatchListPath}/`}>Watch Matches</NavLink>
          </NavItem>

        </>
      )
    }
    return;
  }


  return (
    <Fragment>
      {getLinks()}
    </Fragment >
  )
};

export default NavMenuLinks;

