import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import ArithmeticQuiz from './components/ArithmeticQuiz/ArithmeticQuiz'
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import {ArithmeticQuizPath, SpellingQuizPath, TicketMakerPath, WatchListPath } from './components/Shared/Constants';
import SpellingQuizRouter from './components/SpellingQuiz/SpellingQuizRouter'
import TicketMakerApp from './components/TicketEvent/TicketMakerApp';
import WatchItemRouter from './components/WatchList/WatchItemRouter';
import './custom.css';


export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        <AuthorizeRoute path={`/${ArithmeticQuizPath}/`} component={ArithmeticQuiz} />
        <AuthorizeRoute path={`/${SpellingQuizPath}/`} component={SpellingQuizRouter} />
        <AuthorizeRoute path={`/${WatchListPath}/`} component={WatchItemRouter} />
        <AuthorizeRoute path={`/${TicketMakerPath}/`} component={TicketMakerApp} />
      </Layout>
    );
  }
}
