import React, { useState } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import OutcomeFilterButtonList from './OutcomeFilterButtonList';
import FilterCard from './Contests/FilterCard'

const TicketFilterUI = ({ contestKeys, outcomeKeys, outcomeFilters, updateFilters, resetAllContestFilters }) => {

    const [open, setOpen] = useState(false);
    const [activeModalContestIndex, setActiveModalContestIndex] = useState();

    const toggle = () => setOpen(!open);

    const getCurrentFiltetName = (contestIndex, outcomeKeys, outcomeFilters) => {
        let currentOutcomeFilterIndex = outcomeFilters[contestIndex].findIndex((filter) => filter);
        let outcomeKeyFilter = outcomeKeys.findIndex((outcomeKey) => (outcomeKey.contestIndex === contestIndex && outcomeKey.outcomeIndex === currentOutcomeFilterIndex))
        return outcomeKeys[outcomeKeyFilter].name;
    }

    return (
        <>
            <Row className='my-3'>
                <Col xs='12' className='mb-2'>
                    <h1>Filter Tickets</h1>
                    <hr />
                </Col>
                {outcomeFilters !== undefined && contestKeys.map((contest, index) => {
                    return (
                        <Col xs='12' md='6' lg='4' className='my-2 d-flex  ' key={index}>
                        <FilterCard  {...contest}
                             filterIsSet={outcomeFilters[index].includes(true)}
                             currentFilterName={outcomeFilters[index].includes(true) ? getCurrentFiltetName(index, outcomeKeys, outcomeFilters, contestKeys) : "No Filter" }
                            updateFilter={() => { setActiveModalContestIndex(index); toggle() }}/>
                        </Col>
                    )


                })}

            </Row>
            <Row>
                <Modal returnFocusAfterClose={false} isOpen={open}  >
                    <ModalBody>
                        {activeModalContestIndex !== undefined &&
                            <OutcomeFilterButtonList
                                contest={contestKeys[activeModalContestIndex]}
                                outcomeFiltersArray={outcomeFilters[activeModalContestIndex]}
                                outcomeKeys={outcomeKeys.filter(x => x.contestIndex === activeModalContestIndex)}
                                clearContestFilters={() => { resetAllContestFilters(activeModalContestIndex); toggle() }}
                                updateContestFilters={(outcomeIndexToToggle) => { updateFilters(activeModalContestIndex, outcomeIndexToToggle); toggle() }} />
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggle}>Close</Button>
                    </ModalFooter>
                </Modal>

            </Row>
        </>
    );
}
export default TicketFilterUI;