export  function setSessionlStorageTicketEvent(ticketEvent) {
    try{
        sessionStorage.setItem('ticketEvent'+ ticketEvent.id, JSON.stringify(ticketEvent));
    }catch(ex){
        console.log("in setSessionlStorageTicketEvent catch block exception =", ex)
    }
        
}

export  function getSessionStorageTicketEvent(ticketEventId) {
    let ticketEvent = sessionStorage.getItem('ticketEvent'+ ticketEventId);
    if(ticketEvent === null){
        return null;
    }
    return JSON.parse(ticketEvent);
}


export  function setSessionOutcomeFilters(ticketEventOutcomeFilter, ticketEventId) {
    try{
        sessionStorage.setItem('ticketEventOutcomeFilter'+ ticketEventId, JSON.stringify(ticketEventOutcomeFilter));
    }catch(ex){
        console.log("in setSessionOutcomeFilters catch block exception =", ex)
    }
}

export  function getSessionOutcomeFilters(ticketEventId) {
    let ticketEventOutcomeFilters = sessionStorage.getItem('ticketEventOutcomeFilter'+ ticketEventId);
    if(ticketEventOutcomeFilters === null){
        return null;
    }
    return JSON.parse(ticketEventOutcomeFilters);
}

export  function getSessionPreCreationTicketEventValues() {
    let preCreatedTicketEvent = sessionStorage.getItem('ticketEventCreatation');
    
    if(preCreatedTicketEvent === null){
        return null;
    }
    return JSON.parse(preCreatedTicketEvent);
}

export  function setSessionPreCreationTicketEventValues(preCreationTicketEvent) {
    try{
        sessionStorage.setItem('ticketEventCreatation', JSON.stringify(preCreationTicketEvent));
    }catch{
      
    }
}

export  function removeSessionPreCreationTicketEventValues() {
    try{
        sessionStorage.removeItem('ticketEventCreatation');
    }catch{
        
    }
}
