import React, { Fragment, useEffect } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Card, CardText, CardTitle, Col, Progress, Row } from 'reactstrap';


const GameScoreBoard = ({ answerTallyObject, currentQuestionNumber, totalQuestionAmount, timerTime }) => {


    useEffect(()=>{
        

    }, [])

    return (
        <Fragment>
            <Row>
                <Col xs={{ size: 12 }}>
                    <div className="text-center">{currentQuestionNumber > totalQuestionAmount ? totalQuestionAmount : currentQuestionNumber} of {totalQuestionAmount}
                        <Progress value={currentQuestionNumber} max={totalQuestionAmount} />
                    </div>
                </Col>
            </Row>
            <Row className="text-center mt-3 statusCards d-flex">
                <Col xs={{ size: 6 }} md={{ size: 5 }} className="pr-0 order-md-0 d-flex" >
                    <Card body inverse color="success" className='self-stretch ' >
                        <CardTitle>Correct Answers {answerTallyObject.correctAnswer}</CardTitle>
                        <CardText className='mt-1'>Quickly answered {answerTallyObject.correctAnswerUnderTimeLimit}</CardText>
                    </Card>
                </Col>
                <Col xs={{ size: 6 }} md={{ size: 5 }} className="pl-0 order-md-2 d-flex"  >
                    <Card body inverse color="danger" className='self-stretch d-flex' >
                        <CardTitle>Wrong answers {answerTallyObject.wrongAnswer}</CardTitle>
                        <CardText className='mt-1'>Quickly answered {answerTallyObject.wrongAnswerUnderTimeLimit}</CardText>
                    </Card>
                </Col>
                <Col xs={{ size: 12 }} md={{ size: 2 }} className="order-md-1 align-self-center mt-2 flex-center" >
                    {currentQuestionNumber <= totalQuestionAmount &&
                        <CountdownCircleTimer
                            key={currentQuestionNumber}
                            isPlaying={true}
                            size={120}
                            strokeWidth={6}
                            duration={timerTime}
                            colors={[["#3f51b5"]]}
                        />
                    }
                </Col>
            </Row>
        </Fragment >
    )
};

export default GameScoreBoard;

