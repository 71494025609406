import React, { useState, Fragment, useEffect, useRef } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { speak } from '../systemUtilities/SpeechSyntesisUtilities';
import { getDefinition } from '../systemUtilities/fetchUtilities';

const SpellingForm = ({ timerTime, recordAnswer, wordToSpell, correctnessSoundPlayingStatus, voice }) => {

    const [answer, setAnswer] = useState("");
    const [problemStartTime, setProblemStartTime] = useState(() => new Date().getTime())
    const answerInputRef = useRef(null);

    const checkAnswer = () => {
        return wordToSpell.toUpperCase() === answer.toUpperCase();
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (answer.trim() === '') {
            return;
        }

        let correctlyAnswered = checkAnswer();
        let timeElasped = (new Date().getTime() - problemStartTime) / 1000 > timerTime;
        let problem = `Question to spell =  ${wordToSpell}, Answer = ${answer}`
        let answerTime = (new Date().getTime() - problemStartTime) / 1000;
        let word = wordToSpell
        recordAnswer({ correctlyAnswered, timeElasped, problem, answerTime, word });
        setAnswer('');
    }

    useEffect(() => {
        answerInputRef.current.focus();
    }, [])

    useEffect(() => {
        if (correctnessSoundPlayingStatus === "STOPPED") {
            speak("Your next word is " + wordToSpell, voice, null)
            setProblemStartTime(new Date().getTime());
        }
    }, [wordToSpell, correctnessSoundPlayingStatus, voice])

    const sayWord = () => {
        speak(wordToSpell, voice, null);
        answerInputRef.current.focus();
    }



    const hearDefinition = async () => {
        await getDefinition(wordToSpell)
            .then((apiResponse) => {
                if (apiResponse.data == null) {
                    speak("definition is not avaible", voice, null);
                }

                var dictionaryEntriesWithDefinitions = apiResponse.data.filter(element => element.definition !== '');

                if (dictionaryEntriesWithDefinitions) {
                    var combinedDefinitionsString = '';
                    for (let i = 0; i < dictionaryEntriesWithDefinitions.length; i++) {

                        combinedDefinitionsString = `${combinedDefinitionsString} Definition #${i + 1} ${dictionaryEntriesWithDefinitions[i].definition}                                                                                                                             `
                    }
                    speak(combinedDefinitionsString, voice, null);
                }
                else {
                    speak("definition is not avaible", voice, null);
                }
            });

    }

    return (
        <Fragment>
            <Row className='my-2'>
                <Col xs={12} md={{ size: 2, offset: 3 }} className='mb-2 mb-md-0'>
                    <Button   block onClick={sayWord} >Word</Button>
                </Col>
                <Col xs={12} md={{ size: 2, offset: 2 }} className='mb-2 mb-md-0' >
                    <Button   block onClick={hearDefinition}>Definition</Button>
                </Col>

            </Row>
            <Row>
                <Col xs={12} className='hugeFont text-center'>

                    <form onSubmit={handleFormSubmit} autoComplete="off">
                        <input id={"Answer"}
                            onChange={(e) => setAnswer(e.target.value.replace(/[^a-zA-Z]+/g, ''))}
                            value={answer}
                            ref={answerInputRef}
                            className='form-control'
                        />
                    </form>
                </Col>
                <Col xs={12} md={{ size: 2, offset: 5 }} className='mt-3' >
                    <Button color="primary"  block onClick={handleFormSubmit}>Submit</Button>
                </Col>
            </Row>
        </Fragment>
    )
};

export default SpellingForm;
