import React from 'react';
import { Row, Col } from 'reactstrap';


const OutcomeFilterButtonList = ({contest, outcomeKeys, outcomeFiltersArray, clearContestFilters, updateContestFilters }) => {
    return (
<Row>
    <Col xs={12}>
        <h3>{contest.name}</h3>
    </Col>
    <Col xs={12}>
    <div className="btn-group btn-group-toggle flex-wrap  d-flex" data-toggle="buttons">
    <label className={outcomeFiltersArray.includes(true) ? "btn btn-secondary  my-2 " : "btn btn-primary active  my-2 "  }>
        <input type="checkbox" onClick={clearContestFilters} autoComplete="off" value='' />No Filter
    </label>
    {outcomeKeys.map((outcome, index) => {
        return (
            <label key={index} className={outcomeFiltersArray[index] ? "btn btn-primary active my-2 " : "btn btn-secondary my-2 "}>
                <input onClick={() => updateContestFilters(index)} type="checkbox" />{outcome.name}
            </label>)

    }
    )}
</div>
    </Col>
</Row>        
        
    )
};

export default OutcomeFilterButtonList;

