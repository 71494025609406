import React from 'react';
import { Button, Card, CardBody, CardTitle, CardHeader, CardText } from 'reactstrap';


const FilterCard = ({ contestIndex, name, filterIsSet, currentFilterName, updateFilter }) => {

    return (

        <Card className='d-flex flex-fill text-center'>
            <CardHeader>
                Contest {contestIndex + 1}
            </CardHeader>
            <CardBody className='d-flex flex-column flex-grow-1' >
                <CardTitle>
                    {name}
                </CardTitle>
                <div className='d-flex flex-grow-1 flex-column mb-3'>
                    {filterIsSet && <CardText>Current Filter: {currentFilterName}</CardText>}
                    {!filterIsSet && <CardText>Current Filter: No filter</CardText>}
                </div>
                <Button color="primary" className="mb-1" style={{ marginBottom: '1rem' }} onClick={updateFilter}>
                    Change Filter
                </Button>
            </CardBody>
        </Card>

    )

};

export default FilterCard;
