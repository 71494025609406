import React, { useState, useEffect, Fragment } from 'react';
import SpellingForm from './SpellingForm';
import Sound from 'react-sound';
import GameScoreBoard from '../Shared/GameScoreBoard';
import AllDoneIcon from '../Shared/AllDoneIcon';
import { postAnswers } from '../systemUtilities/fetchUtilities';
import RetypeWrongWord from './RetypeWrongWord';
import Review from './Review';

const SpellQuiz = (props) => {

    const getSpellingWordList = () => {
        const lists = [
            ["cow", "howl", "brown", "down", "count", "ouch", "ground", "mouse", "found", "about", "William"],
            ["stripe", "string", "scream", "scrap", "spring", "spray", "splash", "split", "school", "through", "Isla"],
            ["sad", "jam", "fix", "got", "kick", "plus", "bend", "stop", "with", "him", "Audra"],
            ["lamb", "crumb", "wrap", "wrist", "wrench", "knob", "know", "knee", "friend", "every", "Peter"],
            ["moon", "room", "pool", "noon", "wood", "took", "cook", "hood", "Liz", "William"],
            ["chip", "with", "thin", "shop", "bunch", "itch", "when", "thought", "pitch", "rush", "play"],
            ["green", "deep", "maybe", "clean", "team", "theme", "niece", "relief", "believe", "cheese", "stop"],
            ["cot", "face", "cage", "city", "gave", "gym", "brag", "page", "special", "again", "summer"],
            ["yard", "large", "sharp", "park", "want", "farm", "watch", "party", "jar", "start", "gabe"],
            ["bike", "ride", "smile", "have", "plate", "tape", "grade", "nice", "fine", "name", "Tom"],
            ["wrote", "phone", "soap", "goat", "glow", "throw", "toes", "hold", "one", "some", "Liz"],
            ["cucumber", "rescue", "using", "used", "value", "few", "fewer", "mute", "to", "second", "Paul"],
            ["rice", "tie", "lie", "night", "right", "cry", "fly", "find", "tried", "favorite", "Paula"],
            ["rake", "cave", "mail", "paint", "drain", "away", "clay", "table", "always", "they", "Douglas"],
            ["tube", "flute", "grew", "stew", "blew", "glue", "due", "truth", "knew", "into", "Joyce"],
            ["fort", "born", "more", "store", "board", "your", "door", "roar", "before", "our", "dog", "cat"],
            ["person", "burn", "serve", "first", "burn", "learn", "nurse", "earth", "heard", "world", "Murphy"],
            ["August", "sauce", "salt", "law", "brought", "straw", "because", "aunt", "walk", "taught"]

        ]

        if (props.match.params.unitId && parseInt(props.match.params.unitId) && lists.length >= parseInt(props.match.params.unitId)) {
            console.log(lists[parseInt(props.match.params.unitId) - 1]);
            return lists[parseInt(props.match.params.unitId) - 1];
        }
        let randomWordList = [];
        for (let i = 0; i < 12; i++) {
            let randomList = lists[getRandomInt(lists.length)];
            randomWordList.push(randomList[getRandomInt(randomList.length)])
        }
        return randomWordList;

    }

    const [answerTallyObject, setAnswerTallyObject] = useState({ correctAnswer: 0, correctAnswerUnderTimeLimit: 0, wrongAnswer: 0, wrongAnswerUnderTimeLimit: 0, answerRecordArray: [] });
    const [currentQuestionNumber, setcurrentQuestionNumber] = useState(1);
    const [soundUrl, setSoundUrl] = useState("");
    const [playingStatus, setPlayingStatus] = useState("STOPPED");
    const [timerTime, setTimerTime] = useState(30);
    const [spellingWordList] = useState(getSpellingWordList());
    const [pageStatus, setPageStatus] = useState('Review');
    const [retypeWord, setRetypeWord] = useState('');

    function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    const recordAnswer = (newAnswer) => {
        let newTallyAnswerObject = { ...answerTallyObject }
        if (newAnswer.correctlyAnswered) {
            newTallyAnswerObject.correctAnswer = answerTallyObject.correctAnswer + 1;
            if (!newAnswer.timeElasped) {
                newTallyAnswerObject.correctAnswerUnderTimeLimit = answerTallyObject.correctAnswerUnderTimeLimit + 1;
            }
            setSoundUrl('SoundFiles/correctAnswer.mp3');
            setTimerTime(30);
        } else {
            newTallyAnswerObject.wrongAnswer = answerTallyObject.wrongAnswer + 1;
            if (!newAnswer.timeElasped) {
                newTallyAnswerObject.wrongAnswerUnderTimeLimit = answerTallyObject.wrongAnswerUnderTimeLimit + 1;
            }
            setSoundUrl('SoundFiles/wrongAnswer.mp3');
            setPageStatus('RetypeWrongAnswer');
            setRetypeWord(newAnswer.word)
            setTimerTime(0);
        }
        answerTallyObject.answerRecordArray.push(newAnswer);
        newTallyAnswerObject.answerRecordArray = [...answerTallyObject.answerRecordArray];
        setAnswerTallyObject(newTallyAnswerObject);
        setPlayingStatus("PLAYING");
        setcurrentQuestionNumber(currentQuestionNumber + 1);
    }

    const completedWrongWordTypeExcersise = () => {
        setTimerTime(30);
        setPageStatus('Quiz');
    }

    const handleFinishPlaying = () => {
        setPlayingStatus("STOPPED");
    }

    useEffect(() => {
        if (spellingWordList && currentQuestionNumber > spellingWordList.length) {
            postAnswers(answerTallyObject.answerRecordArray)
                .then((response) => {  })
                .catch((error) => {  })
        }
    }, [currentQuestionNumber, spellingWordList, answerTallyObject.answerRecordArray])

    return (
            <Fragment>
                <Sound autoLoad={false} playStatus={playingStatus} url={soundUrl} onFinishedPlaying={handleFinishPlaying} />

                {(pageStatus === 'Review') &&
                    <Fragment>
                        <Review onComplete={() => setPageStatus('Quiz')} list={spellingWordList} voice={props.voice} />
                    </Fragment>
                }

                {(currentQuestionNumber <= spellingWordList.length && pageStatus === 'Quiz') &&
                    <Fragment>
                        <GameScoreBoard answerTallyObject={answerTallyObject} currentQuestionNumber={currentQuestionNumber} totalQuestionAmount={spellingWordList.length} timerTime={timerTime} />
                        <SpellingForm timerTime={timerTime} operation={props.operation} recordAnswer={recordAnswer} spellingWordList={spellingWordList} currentQuestionNumber={currentQuestionNumber} wordToSpell={spellingWordList[currentQuestionNumber - 1]} correctnessSoundPlayingStatus={playingStatus} voice={props.voice} />
                    </Fragment>
                }

                {(currentQuestionNumber <= spellingWordList.length && pageStatus === 'RetypeWrongAnswer') &&
                    <RetypeWrongWord word={retypeWord}
                        correctnessSoundPlayingStatus={playingStatus}
                        onComplete={completedWrongWordTypeExcersise}
                        voice={props.voice} />
                }
                {(currentQuestionNumber > spellingWordList.length && pageStatus === 'Quiz') &&
                    <Fragment>
                        <GameScoreBoard answerTallyObject={answerTallyObject}
                            currentQuestionNumber={currentQuestionNumber}
                            totalQuestionAmount={spellingWordList.length}
                            timerTime={timerTime} />
                        <AllDoneIcon />
                    </Fragment>
                }
            </Fragment>
    )
};
export default SpellQuiz;
