import React from 'react';
import { Row, Col, Alert } from 'reactstrap';


const ErrorMessage = ({
    errorMessage,
    alertType = "danger"
}) => {
    return (
        <Row className="mt-3 mb-3">
            <Col xs={12}>
                <Alert color={alertType}>
                    {errorMessage}
                </Alert>
            </Col>
        </Row>

    )
};
export default ErrorMessage;




