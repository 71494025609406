import React, { useEffect, useState } from 'react';
import Sound from 'react-sound';
import AllDoneIcon from '../../components/Shared/AllDoneIcon';
import { postAnswers } from '../systemUtilities/fetchUtilities';
import GameScoreBoard from '../Shared/GameScoreBoard';
import ArithmeticForm from './ArithmeticForm';

const MathQuiz = (props) => {

    
    const [answerTallyObject, setAnswerTallyObject] = useState({ correctAnswer: 0, correctAnswerUnderTimeLimit: 0, wrongAnswer: 0, wrongAnswerUnderTimeLimit: 0, answerRecordArray: [] });
    const [currentQuestionNumber, setcurrentQuestionNumber] = useState(1);
    const [totalQuestionAmount] = useState(props.operationOption?.numberOfQuestions || 10);
    const [soundUrl, setSoundUrl] = useState("");
    const [playingStatus, setPlayingStatus] = useState("STOPPED");
    const [timerTime] = useState(props.operationOption?.secondsToAnswer || 10);
    

    
    const recordAnswer = (newAnswer) => {
      
        let newTallyAnswerObject = { ...answerTallyObject }
        if (newAnswer.correctlyAnswered) {
            newTallyAnswerObject.correctAnswer = answerTallyObject.correctAnswer + 1;
            if (!newAnswer.timeElasped) {
                newTallyAnswerObject.correctAnswerUnderTimeLimit = answerTallyObject.correctAnswerUnderTimeLimit + 1;
            }
            setSoundUrl('SoundFiles/correctAnswer.mp3');
        } else {
            newTallyAnswerObject.wrongAnswer = answerTallyObject.wrongAnswer + 1;
            if (!newAnswer.timeElasped) {
                newTallyAnswerObject.wrongAnswerUnderTimeLimit = answerTallyObject.wrongAnswerUnderTimeLimit + 1;
            }
            setSoundUrl('SoundFiles/wrongAnswer.mp3');

        }
        answerTallyObject.answerRecordArray.push(newAnswer);
        newTallyAnswerObject.answerRecordArray = [...answerTallyObject.answerRecordArray];

        setAnswerTallyObject(newTallyAnswerObject);
        setPlayingStatus("PLAYING");
        setcurrentQuestionNumber(currentQuestionNumber + 1);
    }

    const handleFinishPlaying = () => {
        setPlayingStatus("STOPPED");
    }

    useEffect(() => {
        
        if (currentQuestionNumber > totalQuestionAmount) {
            postAnswers(answerTallyObject.answerRecordArray)
                .then((response) => {  })
                .catch((error) => {  })
        }
    }, [answerTallyObject.answerRecordArray, currentQuestionNumber, totalQuestionAmount])

    return (
        <div>
            <Sound autoLoad={false} playStatus={playingStatus} url={soundUrl} onFinishedPlaying={handleFinishPlaying} />
            <GameScoreBoard answerTallyObject={answerTallyObject} currentQuestionNumber={currentQuestionNumber} totalQuestionAmount={totalQuestionAmount} timerTime={timerTime} />

            {currentQuestionNumber <= totalQuestionAmount &&
                
               <ArithmeticForm timerTime={timerTime}  recordAnswer={recordAnswer}  {...props.operationOption}   />
            }
            {currentQuestionNumber > totalQuestionAmount &&
                <AllDoneIcon />
            }
        </div>
    )
};

export default MathQuiz;

