import React from 'react';

const ProcessingSpinner = ({
    message = "Loading"
}) => {
    return (
        <div className=" d-flex align-items-center justify-content-center my-5 min-vh-10">
            <div className=" d-flex align-items-center">
                <div className="text-center mr-3 processingSpinnerText">
                    {message}
                </div>
                <div className="spinner-border processingSpinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    )
};

export default ProcessingSpinner;

