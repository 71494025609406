import React, { useState, useEffect, Fragment } from 'react';
import { Route } from 'react-router';
import MathQuiz from './MathQuiz';
import { getQuizAdmin } from '../systemUtilities/fetchUtilities';
import ProcessingSpinner from '../Shared/ProcessingSpinner';
import ErrorMessage from '../Shared/ErrorMessage';
import ArithmeticAdmin from './ArithmeticAdmin';

const ArithmeticQuiz = () => {

    const [pageStatus, setPageStatus] = useState("loading");
    const [adminObject, setAdminObject] = useState();


    const handleGetQuizAdminResponse = (getQuizAdminResponse) => {
        setAdminObject(getQuizAdminResponse)
        setPageStatus("ready");
    }

    useEffect(() => {
            getQuizAdmin().then(handleGetQuizAdminResponse);
    },[]);



    return (
        <>
            {pageStatus === "error" &&
                <ErrorMessage errorMessage="Sorry, something went wrong. Try again later." />
            }

            {pageStatus === "loading" &&
                <ProcessingSpinner />
            }

            {pageStatus === "ready" &&
                <Fragment>
                    <Route path='/Arithmetic/Addition/' render={(props) => <MathQuiz  operationOption={adminObject.operationOptions.find(x => x.operation === '+')??{operation:'+'}} />} />
                    <Route path='/Arithmetic/Subtraction/' render={(props) => <MathQuiz  operationOption={adminObject.operationOptions.find(x => x.operation === '-')??{operation:'-'}} />}  />
                    <Route path='/Arithmetic/Multiplication/' render={(props) => <MathQuiz  operationOption={adminObject.operationOptions.find(x => x.operation === 'x')??{operation:'x'}} />} />
                    <Route path='/Arithmetic/Division/' render={(props) => <MathQuiz  operationOption={adminObject.operationOptions.find(x => x.operation === '/')??{operation:'/'}} />} />
                    <Route path='/Arithmetic/Admin/' render={(props) => <ArithmeticAdmin adminObject={adminObject} setAdminObject={setAdminObject} />} />
                </Fragment>
            }
        </>
    )
};

export default ArithmeticQuiz;
