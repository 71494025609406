import React from 'react';

const FormFieldWrap = ({
    id,
    outerDivClassName = 'form-group ',
    labelClassName = '',
    innerLabelText = id,
    helpText = '',
    helpTextClassName = 'form-text text-muted',
    errorMessage = "",
    errorMessageClassName = 'validation_message',
    children,
    isRequired = true,
    isRequiredCssClass = 'validation_message',
    renderLabel = true,
    flexDisplay = true
}) => {


    const outer = {
        display: 'table',
        verticalAlign: 'bottom'
    }
    
    const inner = {
        display: 'table-cell',
        verticalAlign: 'bottom',
        paddingBottom:'10px'
    }
    

    return (
        <div className={outerDivClassName + (flexDisplay ? ' d-flex ' : '') + ' flex-grow-1 flex-column ' + (errorMessage !== '' ? ' invalid '  : '')}>
            {renderLabel &&
            <div className='' style={outer}>
                    <label style={inner} className={labelClassName + ' equalHeightLabel '} htmlFor={id}>{innerLabelText}
                        {isRequired && <span className={isRequiredCssClass}>&nbsp;*</span>}
                    </label>
             </div>
            }
            <div className="d-flex flex-column" >
                {children}
            </div>
            <div className='eqaulHeightInputFooter'>
                {helpText && <small className={helpTextClassName}>{helpText}</small>}
                {errorMessage && <span className={errorMessageClassName}>{errorMessage}</span>}
            </div>
        </div>
    );
};

export default FormFieldWrap;
