import React, { useState } from 'react';
import { Button, Row, Col, ButtonGroup } from 'reactstrap';
import FormFieldWrap from '../../../components/Shared/FormFieldWrap';
import * as yup from 'yup';

const ContestForm = (props) => {

    const [name, setName] = useState(props.name === undefined ? '' : props.name);
    const [nameError, setNameError] = useState();
    const [outcomes, setOutcomes] = useState(props.outcomes === undefined ? ['', ''] : props.outcomes);
    const [outcomesErrorArray, setOutcomesErrorArray] = useState(Array(outcomes.length).fill(''));

    let validationSchema = yup.object().shape({
        name: yup.string().required(() => setNameError('Required')),
        outcomes: yup.array().test(function outcomeRequired(){
            let valid = true;
            for(let i = 0; i < outcomes.length; i++)
            {
                if(outcomes[i].trim() === '')
                {
                    valid = false;
                    outcomesErrorArray[i] = 'Required';
                    setOutcomesErrorArray([...outcomesErrorArray])    
                }
            }
            return valid;
        })
        
    });

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setNameError('');
        setOutcomesErrorArray([...new Array(outcomes.length).fill('')]);
        console.log(outcomesErrorArray);
        let valid = await validationSchema.isValid({
            name,
            outcomes,
        }).then((valid) => {
                return valid;
        });
        if (valid) {
            props.handleValidSubmit({name, outcomes}, props.contestIndex);
        }
    }

    const updateOutcome = (updatedOutcome, outcomeIndexToUPdate) => {
        setOutcomes(outcomes.map((outcome, index) => { return index === outcomeIndexToUPdate ? updatedOutcome : outcome }))
    }

    const addOutcomeInput = () => {
        setOutcomesErrorArray([...outcomesErrorArray, '']);
        setOutcomes([...outcomes, ''])
    }

    const removeOutcomeInput = () => {
        if (outcomes.length > 2) {
            outcomes.pop();
            setOutcomes([...outcomes])
        } else {
            alert("2 or more outcomes required")
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col sm={12}>
                    <FormFieldWrap id='Name'
                        errorMessage={nameError}
                        innerLabelText='Name'>
                        <input id='Name'
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            className='form-control' />
                    </FormFieldWrap>
                </Col>

                {outcomes.map((outcome, outcomeIndex) =>
                    <Col key={outcomeIndex} sm={12}>
                        <FormFieldWrap id={'outcome' + outcomeIndex}
                            errorMessage={outcomesErrorArray[outcomeIndex]}
                            innerLabelText={'Outcome ' + (outcomeIndex + 1).toString()}>
                            <input id={'outcome' + outcomeIndex}
                                onChange={(e) => updateOutcome(e.target.value, outcomeIndex)}
                                value={outcome} 
                                className='form-control' />
                        </FormFieldWrap>
                    </Col>
                )}

                <Col sm={12}>
                    <ButtonGroup className="special">
                        <Button onClick={addOutcomeInput} outline color='secondary'>Add Outcome</Button>
                        <Button onClick={removeOutcomeInput} outline color='danger'>Remove Outcome</Button>
                    </ButtonGroup>
                </Col>
                <Col sm="12 mt-3">
                    <Button type='submit' color='primary' className='btn-block' >Submit</Button>
                </Col>
            </Row>
        </form>
    )
};

export default ContestForm;
