import React from 'react';
import { Col, Row } from 'reactstrap';

const AllDoneIcon = () => {

    return (

        <Row className="AllDoneIcon text-center mt-3">
            <Col xs={{ size: 12 }}>
                <h2>All Done!</h2>
            </Col>
            <Col xs={{ size: 12 }}>
                <i class="far fa-smile-beam"></i>
            </Col>
        </Row>

    )
};

export default AllDoneIcon;

