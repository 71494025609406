import React from 'react';
import { Button, Card, CardBody, CardText, CardTitle, CardHeader, ButtonGroup, Row, Col } from 'reactstrap';

const ConstestCard = ({
    name,
    outcomes,
    contestIndex,
    handleDeleteClick,
    handleEditClick
}) => {
    return (
        
        <Card className='d-flex flex-fill'>
            <CardHeader>
                Contest {contestIndex + 1}
            </CardHeader>
            <CardBody className='d-flex flex-column flex-grow-1' >
                <CardTitle>
                    {name}
                </CardTitle>
                <div className='d-flex flex-grow-1 flex-column mb-3'>
                    {outcomes.map((outcome, outcomeIndex) =>
                        <CardText className='' key={outcomeIndex}>
                            {outcome}
                        </CardText>
                    )}
                </div>
                <Row>
                    <Col sm="12">
                        <ButtonGroup className="special">
                            <Button onClick={handleEditClick} outline color="info" >Edit</Button>
                            <Button onClick={handleDeleteClick} outline color='danger' >Remove</Button>
                        </ButtonGroup>
                    </Col>

                </Row>
            </CardBody>
        </Card>
    );
}
export default ConstestCard;