import React, { Fragment } from 'react';
import TicketCardDeck from '../TicketEvent/TicketCardDeck'
import { Row, Col, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

const TicketDisplay = ({ ticketEvent, showParticipants, particpantName }) => {

    return (
        <Fragment>
            <Row>
                {showParticipants ?
                    <Col xs='12'>
                        <h1>Tickets</h1>

                    </Col> :
                    <Fragment>
                        <Col md='10'>
                            <h1>{particpantName}'s Tickets</h1>
                        </Col>
                        <Col md='2'>
                            <Link className='justify-content-center align-self-center' to={"/TicketMaker/Event/" + ticketEvent.id}>Click to see all Tickets</Link>
                        </Col>

                    </Fragment>

                }
                <hr />
            </Row>  
            <TicketCardDeck contestKeys={ticketEvent.contestKeys} tickets={ticketEvent.tickets} participants={ticketEvent.participants} showParticipants={showParticipants} />
            
            <Row id='NoTicketsLeftWarning' className='d-none'>
                <Col xs={12}>
                    <Alert color="danger">
                        <p>No tickets left to display</p>
                    </Alert>
                </Col>
            </Row>
        </Fragment>
    )
};

export default TicketDisplay;
