import authService from '../api-authorization/AuthorizeService';

async function sendData(url, data, httpVerb) {
    let commonObejct = await getCommonFetchObject();
    return fetch(url, {
        ...commonObejct,
        method: httpVerb,
        body: JSON.stringify(data)
    }).then(status)
        .then(json)
}

export async function getEventById(id) {
    const response = await fetch('api/TicketEvent?ticketEventId=' + id, {
        ...getCommonFetchObject(),
        method: 'GET',
    });
    return await response.json();
}

async function getCommonFetchObject() {
    const token = await authService.getAccessToken();
    return {
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    }
}



function status(response) {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response)
    } else {
        return Promise.reject(new Error("Network error"))
    }
}

function json(response) {
    try {
        return response.json()
    } catch (error) {

        return Promise.resolve({});
    }
}

export function postNewTicketEvent(ticketEvent) {
    return sendData('api/TicketEvent', ticketEvent, "Post")
        .catch((error) => { Promise.reject(error); });
}

export async function postAnswers(answers) {
    return await sendData('api/QuizReporting', answers, "Post")
        .catch((error) => Promise.reject(error));
}

export function toggleWifiStatus() {
    return sendData('api/RouterWifi', {}, "Post")
        .catch((error) => Promise.reject(error));
}

export function getQuizAdmin() {
    return fetch('api/MathQuizAdmin', {
        ...getCommonFetchObject(),
        method: 'GET',
    }).then(status)
        .then(json)
}

export function saveQuizAdmin(adminObject) {

    return sendData('api/MathQuizAdmin', adminObject, "Post")
        .catch((error) => Promise.reject(error));
}


export function getDefinition(word) {
    return fetch('api/Definition?word=' + word, {
        ...getCommonFetchObject(),
        method: 'GET',
    }).then(status)
        .then(json)
}



export function getWatchListIfMatched() {
    return getWatchListIndex(true)
 }

export function getWatchList() {
   return getWatchListIndex(false)
}

function getWatchListIndex(onlyMatchedItems){
    return fetch('api/WatchItem?withMatchItemsOnly='+ onlyMatchedItems, {
        ...getCommonFetchObject(),
        method: 'GET',
    }).then(status)
        .then(json)
}

export function putWatchItem(data) {

    return sendData('api/WatchItem', data, "Put")
        .catch((error) => { Promise.reject(error); });
}

export function postWatchItem(data) {

    return sendData('api/WatchItem', data, "Post")
        .catch((error) => { Promise.reject(error); });
}

export function deleteWatchItem(data) {
    return sendData('api/WatchItem', data, "Delete")
        .catch((error) => { Promise.reject(error); });
}


export function getMatchList(watchItemId) {
    let apiAddress = watchItemId ? `api/WatchItemMatch?watchItemId=${watchItemId}` : 'api/WatchItemMatch';   
    return fetch(apiAddress, {
        ...getCommonFetchObject(),
        method: 'GET',
    }).then(status)
        .then(json)
}


export function deleteMatchList(Id) {
    return sendData('api/WatchItemMatch', {Id}, 'Delete');
}


// async function sendData(url, data, httpVerb) {
//     let commonObejct = await getCommonFetchObject();
//     return fetch(url, {
//         ...commonObejct,
//         method: httpVerb,
//         body: JSON.stringify(data)
//     }).then(status)
//         .then(json)
// }
