import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';

const ArithmeticForm = ({ timerTime, operation, recordAnswer , min, max}) => {
    const [firstDigit, setFirstDigit] = useState(0);
    const [secondDigit, setSecondDigit] = useState(0);
    const [answer, setAnswer] = useState("");
    const [problemStartTime, setProblemStartTime] = useState(() => new Date().getTime())
    const [minValue] = useState((min === undefined) ? 1 : min );
    const [maxValue] = useState((max === undefined) ? 11 : max );
    const [time] = useState(timerTime);
    function getRandomInt( ) {
        let localmin = Math.ceil(minValue);
        let localmax = Math.floor(maxValue);
        return Math.floor(Math.random() * (localmax - localmin)) + localmin; //The maximum is exclusive and the minimum is inclusive
    }

    const createNewProblem = () => {
        if (operation === '+') {
            createNewAdditionProblem();
        }

        if (operation === '-') {
            createNewSubtractionProblem();
        }

        if (operation === 'x') {
            createNewMultiplicationProblem();
        }
        
        if (operation === '/') {
            createNewDivisionProblem();
        }
        
        setAnswer('');
        setProblemStartTime(new Date().getTime())
    }

    useEffect(createNewProblem, [])


    const checkAnswer = () => {
        if (operation === '+') {
            return firstDigit + secondDigit === parseInt(answer, 10);
        }

        if (operation === '-') {
            return firstDigit - secondDigit === parseInt(answer, 10);
        }

        if (operation === 'x') {
            return firstDigit * secondDigit === parseInt(answer, 10);
        }

        if (operation === '/') {
            return firstDigit / secondDigit === parseInt(answer, 10);
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (answer.trim() === '') {
            return;
        }

        let correctlyAnswered = checkAnswer();
        let timeElasped = (new Date().getTime() - problemStartTime) / 1000 > time;
        let problem = `${firstDigit} ${operation} ${secondDigit} = ${answer}`;
        let answerTime = (new Date().getTime() - problemStartTime) / 1000;
        recordAnswer({ correctlyAnswered, timeElasped, problem, answerTime });
        createNewProblem();
    }


    const createNewAdditionProblem = () => {
        setFirstDigit(getRandomInt());
        setSecondDigit(getRandomInt())
    }

    const createNewMultiplicationProblem = () => {
        setFirstDigit(getRandomInt());
        setSecondDigit(getRandomInt())
    }

    const createNewSubtractionProblem = () =>{
        let firstDigit = getRandomInt();
        let secondDigit = getRandomInt();

        if (firstDigit < secondDigit) {
            let temp = firstDigit;
            firstDigit = secondDigit;
            secondDigit = temp;
        }

        setFirstDigit(firstDigit);
        setSecondDigit(secondDigit);
    }

    const createNewDivisionProblem = () =>{
        let firstDigit = getRandomInt();
        let secondDigit = getRandomInt();

        if(secondDigit === 0){
            secondDigit = 1;
        }
         
        setFirstDigit(firstDigit * secondDigit);
        setSecondDigit(secondDigit);
    }

    const handleAnswerChange = (e) => {
        if (e.target.value.trim() === '') {
            setAnswer('');
            return;
        }

        if (isInt(e.target.value)) {
            setAnswer(e.target.value)
            return;
        }
    }

    function isInt(value) {
        return !isNaN(value) &&
            parseInt(Number(value)) ==  /* eslint eqeqeq: 0 */ /* purposeful == */  value &&
            !isNaN(parseInt(value, 10));
    }

    function getOperationIconClass() {
        const className = 'mathOperatorIcon';

        if (operation === '+') {
            return className + " fas fa-plus"
        }
        if (operation === '-') {
            return className + " fas fa-minus"
        }
        if (operation === 'x') {
            return className + " fas fa-times"
        }

        if (operation === '/') {
            return className + " fas fa-divide"
        }

        return className;
    }

    return (

        <form onSubmit={handleFormSubmit}>
            <div className='hugeFont text-center'>
                <Row>
                    <Col xs={{ size: 4, offset: 4 }} sm={{ size: 4, offset: 4 }} md={{ size: 2, offset: 5 }}>{firstDigit}</Col>
                </Row>
                <Row>
                    <Col xs={{ size: 4 }} sm={{ size: 2, offset: 2 }} md={{ size: 2, offset: 3 }} className='text-right'> <i className={getOperationIconClass()}></i></Col>
                    <Col xs={{ size: 4 }} sm={{ size: 4 }} md={{ size: 2 }}>{secondDigit}</Col>
                </Row>
                <Row>
                    <Col sm={{ size: 6, offset: 3 }} md={{ size: 4, offset: 4 }} ><hr /></Col>
                </Row>
                <Row>
                    <Col sm={{ size: 6, offset: 3 }} md={{ size: 4, offset: 4 }} >
                        <input className="inherit-width text-center" type="number" value={answer} onChange={handleAnswerChange} />
                    </Col>
                </Row>
            </div>
        </form>

    )
};

export default ArithmeticForm;

