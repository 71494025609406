import React, { useState } from 'react';
import { Button, Row, Col, } from 'reactstrap';
import FormFieldWrap from '../../../components/Shared/FormFieldWrap';
import * as yup from 'yup';

const ParticipantForm = (props) => {
    
    const [email, setEmail] = useState(props.email === undefined ? '' : props.email);
    const [emailError, setEmailError] = useState();
    const [name, setName] = useState(props.name === undefined ? '' : props.name);
    const [nameError, setNameError] = useState();

    let validationSchema = yup.object().shape({
        email: yup.string().required(() => setEmailError("Required")).email(() => setEmailError("Invalid")),
        name: yup.string().required(() => setNameError('Required'))
    });

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setEmailError('');
        setNameError('');

        let valid = await validationSchema.isValid({
            name,
            email,
        })
            .then((valid) => {
                return valid;
            });
        if (valid) {
            props.handleValidSubmit({email, name}, props.participantIndex)
        } 
    }


    return (

        <form onSubmit={handleSubmit}>
            <Row>
                <Col sm={12}>
                    <FormFieldWrap id='Name'
                        errorMessage={nameError}
                            innerLabelText='Name'>
                        <input id='setName'
                            onChange={(e) => setName(e.target.value)}
                            value={name} 
                            className='form-control'/>
                    </FormFieldWrap>
                </Col>
                <Col sm={12}>
                    <FormFieldWrap id='Email'
                        errorMessage={emailError}
                        innerLabelText='Email'>
                        <input id='Email'
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            className='form-control' />
                    </FormFieldWrap>
                </Col>
                <Col sm={12}>
                    <Button type='submit' color='primary' className='btn-block' >Submit</Button>
                </Col>
            </Row>
        </form>
    )
};

export default ParticipantForm;
