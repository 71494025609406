import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import ParticipantForm from './ParticipantForm';
import ParticipantCard from './ParticipantCard';
import ErrorMessage from '../../Shared/ErrorMessage'


const ParticipantWell = ({ participants, updateParticipants, participantError }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalInEditMode, setModalInEditMode] = useState();
    const [participantIndexToEdit, setParticipantIndexToEdit] = useState();

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    }

    const openCreateModal = () => {
        setModalInEditMode(false)
        toggleModal();
    }

    const handleParticipantsUpdate = (participantToPersist, indexToEdit) => {
        if (indexToEdit === undefined) {
            updateParticipants([...participants, participantToPersist]);
        }
        else {
            updateParticipants(participants.map((participant, index) => { return index === indexToEdit ? participantToPersist : participant }));
        }
        toggleModal();
    }

    const deleteParticipant = (indexToDelete) => {
        updateParticipants(participants.filter((participant, index, ) => { return index !== indexToDelete; }))
    }

    return (
        <div className='create-well my-4 text-center'>
            <Row className="my-3">
                <Col sm="12">
                    <h3>Participants</h3>
                    <hr />
                </Col>
            </Row>
            {participantError &&
                <ErrorMessage errorMessage={participantError} />
            }
            {(participants.length > 0) && <>
                <Row className="my-3">
                    {participants.map((participant, participantIndex) =>
                        <Col md="6 d-flex flex-grow-1 mb-3" key={participantIndex}>
                            <ParticipantCard {...participant}
                                handleDeleteClick={() => { deleteParticipant(participantIndex) }}
                                handleEditClick={() => { setModalInEditMode(true); setParticipantIndexToEdit(participantIndex); toggleModal(); }}
                                participantIndex={participantIndex} />
                        </Col>
                    )}
                </Row>
            </>}

            <Row className="my-3">
                <Col md={{ size: 3,  offset: 9 }} >
                    <Button color="secondary" className="btn-block float-right" onClick={openCreateModal}>Add Participant</Button>
                </Col>
            </Row>

            <Row>
                <Modal isOpen={modalOpen} toggle={toggleModal} >
                    <ModalHeader toggle={toggleModal}>{modalInEditMode === true ? 'Edit ' : 'Create '}Participant</ModalHeader>
                    <ModalBody>
                        <ParticipantForm handleValidSubmit={handleParticipantsUpdate}
                            participantIndex={modalInEditMode ? participantIndexToEdit : undefined}
                            {...(modalInEditMode ? participants[participantIndexToEdit] : {})}
                        />
                    </ModalBody>
                </Modal>
            </Row>
        </div>
    )
};

export default ParticipantWell;
