import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';


const ParticipantLinkList = ({ participants , ticketEventId }) => {
    return (
        <Row  className="mt-3 mb-3">
         <Col xs={12}>
            <h1>Participants</h1>
            <hr/>
            </Col>
                    {participants.map((participant, participantIndex) => {
                       return ( 
                           <Col xs={12} sm={4} md={3} lg={2} className='my-2' key={participantIndex}>
                                <Link className='btn btn-outline-primary btn-block' to={"/TicketMaker/ParticpantView/"+ticketEventId+'/'+participant.id}>{participant.name}</Link>
                           </Col>
                        )
                    })}
        </Row>
    )
};
export default ParticipantLinkList;
