import React, { useState, useEffect, Fragment } from 'react';
import TicketFilterUI from './TicketFilterUI';
import ParticipantLinkList from './ParticipantLinkList';
import { applyOutcomeFilterToTicketDisplay, updateFilters, resetAllContestFilters, getOrCreateOutcomeFilters } from '../systemUtilities/filterUtilities';
import { getTicketEvent } from '../systemUtilities/ticketEventDataInterface';
import TicketDisplay from './TicketDisplay';
import ProcessingSpinner from '../Shared/ProcessingSpinner';
import ErrorMessage from '../Shared/ErrorMessage';

const TicketEventFullView = (props) => {

    const [ticketEvent, setTicketEvent] = useState();
    const [outcomeFilters, setOutcomeFilters] = useState();
    const [pageError, setPageError] = useState("");
    const [pageStatus, setPageStatus] = useState("idle");

    let handleUpdateFiltersClick = (contestIndex, outcomeIndex) => {
        var updatedOutcomeFilters = updateFilters(contestIndex, outcomeIndex, outcomeFilters, ticketEvent.id);
        setOutcomeFilters(updatedOutcomeFilters);
    }

    let handleResetAllContestFiltersClick = (contestIndex) => {
        var updatedOutcomeFilters = resetAllContestFilters(contestIndex, outcomeFilters, ticketEvent.id)
        setOutcomeFilters(updatedOutcomeFilters);
    }


    useEffect(() => {
        if (outcomeFilters === undefined || pageStatus !== 'idle') {
            return
        }
        applyOutcomeFilterToTicketDisplay(outcomeFilters);
    }, [outcomeFilters, pageStatus]);

    useEffect(() => {
        setPageStatus("Getting Ticket Event");
        getTicketEvent(props.match.params.id)
            .then((ticketEvent) => {
                setTicketEvent(ticketEvent);
                setOutcomeFilters(getOrCreateOutcomeFilters(ticketEvent));
                setPageStatus("idle");
            }).catch((error) => {
                setPageError(error.message);
                setPageStatus("idle");
            });

    }, [props.match.params.particpantId, props.match.params.id]);



    return (
        <div>
            {pageError && <ErrorMessage errorMessage={pageError} />}
            {pageStatus === "Getting Ticket Event" &&
                <ProcessingSpinner message="Loading Tickets" />
            }

            {(pageStatus === "idle" && ticketEvent !== undefined)
                &&
                <Fragment>
                    <ParticipantLinkList participants={ticketEvent.participants}
                         ticketEventId={ticketEvent.id} 
                     />

                    <TicketFilterUI contestKeys={ticketEvent.contestKeys} 
                            outcomeKeys={ticketEvent.outcomeKeys} 
                            outcomeFilters={outcomeFilters} 
                            updateFilters={handleUpdateFiltersClick}
                            resetAllContestFilters={handleResetAllContestFiltersClick}
                     />

                    <TicketDisplay ticketEvent={ticketEvent} 
                            showParticipants={true}
                     />
                </Fragment>
            }
        </div>
    )
};

export default TicketEventFullView;

