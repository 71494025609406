import React, { useState, useEffect } from 'react';
import { Row, Col, Alert} from 'reactstrap';
import { getMatchList } from '../systemUtilities/fetchUtilities';
import { deleteMatchList } from '../systemUtilities/fetchUtilities';
import { MatchInput } from './MatchInput';

const MatchList = (props) => {

  const [matches, setMatches] = useState(undefined);

  const handleWatchClick = (matchId) => {
    deleteMatchList(matchId).then(() => {debugger; setMatches(undefined)});
  }

  useEffect(() => {
    debugger;
    if (matches === undefined && props.match.params.watchItemId !== undefined) {
      debugger;
      getMatchList(props.match.params.watchItemId).then((data) => { setMatches(data) })
    }
  }, [matches, props.match.params.watchItemId]);

  return (
    <>
      <Row className='my-3'>
        {matches!== undefined && matches.length > 0 ? matches.map((element) => {
          return (
            <Col key={element.id} md={6}>
              <MatchInput handleWatchClick={handleWatchClick} {...element}></MatchInput>
            </Col>
          )
        }) : <Col><Alert color="info">No Matches</Alert></Col>  
         }
        
      </Row>
    </>
  )
};

export default MatchList;

