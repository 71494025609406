
  
    export  function browserSupported() {
      return window.speechSynthesis;
    }
    
    let getUtterance = (textToSay, voice) =>{
      var utterance = new window.SpeechSynthesisUtterance();
      utterance.voice = voice
      utterance.text = textToSay.replace(/\n/g, '');
      utterance.lang = 'en-US';
      utterance.pitch = 0.8;
      utterance.rate =  1;
      utterance.volume = 1;
      return utterance;
    }
  
    export function speak(textToSay, voice, completedCallBack ) {
      window.speechSynthesis.cancel();
      let utterance = getUtterance(textToSay, voice)
      if(typeof(completedCallBack) === "function"){
        utterance.onend = completedCallBack;
      }
      window.speechSynthesis.speak(utterance);
    }
  
 
  