import React from 'react';
import { speak } from '../systemUtilities/SpeechSyntesisUtilities';
import { ListGroup, ListGroupItem, Button, Col, Row } from 'reactstrap';

const Review = (props) => {


    return (
        <Row>
            <Col xs={12} sm={{ size: 6, offset: 3 }}>
                <ListGroup>
                    {props.list.map((currentWord, index) => { return <ListGroupItem key={index} onClick={() => speak(currentWord, props.voice, null)}>{currentWord}</ListGroupItem> })}
                </ListGroup>
            </Col>
            <Col xs={12} sm={{ size: 6, offset: 3 }} className='mt-3' >
                <Button block={true} onClick={props.onComplete} >I'm ready for the Quiz!</Button>
            </Col>
        </Row>
    )
};

export default Review;

