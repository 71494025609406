import React, { useState, useEffect } from 'react';
import { Row, Input, Button, ModalHeader, Modal, ModalBody, ModalFooter, FormGroup, Label } from 'reactstrap';
import {putWatchItem, postWatchItem} from '../systemUtilities/fetchUtilities';

const WatchListModel = ({ toggle, operation, name, id, isOpen, afterSuccessfulFormSubmit }) => {
    const [localName, setLocalName] = useState(name);
    const [localId, setLocalId] = useState(id);
   
    const removeLocalData = ()=>{
        setLocalId('');
        setLocalName('');
    }

    const handleFormSubmit = (event)=>{
        event.preventDefault();
        if(localName.trim() === '' || localName.length < 4)
        {
            alert("Search terms require 4 characters or more");
            return;
        }

        if(operation === "Update")
        {
            putWatchItem({"Name": localName, "Id":localId })
                .then(afterSuccessfulFormSubmit)
                .then(removeLocalData);
        }else{
            postWatchItem({"Name": localName}).then(afterSuccessfulFormSubmit)
            .then(removeLocalData);
        }
    }

    useEffect(()=>{
        setLocalName(name);
        setLocalId(id);
    }, [name, id ])

    return (
        <>
            <Row>
                <Modal isOpen={isOpen} toggle={toggle}>
                    <form onSubmit={handleFormSubmit}>
                        <ModalHeader toggle={toggle}>{operation} Watch Item</ModalHeader>
                        <ModalBody>
                        <FormGroup>
                            <Label for="exampleEmail">Search Term</Label>
                            <Input 
                                value={localName} 
                                onChange={(e)=>setLocalName(e.target.value)} 
                                name="name" 
                                id="name" 
                                placeholder="Search term"
                                type='text'
                                required />
                        </FormGroup>
                        
                         <Input type='hidden' value={localId}   />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type='submit' onClick={toggle}>Submit</Button>
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </Row>
        </>
    )
};

export default WatchListModel;

