import React, { useState, useEffect } from 'react';
import { Row, Col, } from 'reactstrap';
import FormFieldWrap from '../../components/Shared/FormFieldWrap';

const ArithmeticAdminSignForm = (props) => {
    const [operation] = useState(props.operationOptions.operation);
    const [min, setMin] = useState(props.operationOptions.min);
    const [max, setMax] = useState(props.operationOptions.max);
    const [numberOfQuestions, setNumberOfQuestions] = useState(props.operationOptions.numberOfQuestions);
    const [secondsToAnswer, setSecondsToAnswer] = useState(props.operationOptions.secondsToAnswer);

    useEffect(()=>{
        props.handleChange({
            operation : operation,
            min : min,
            max : max,
            numberOfQuestions : numberOfQuestions,
            secondsToAnswer : secondsToAnswer
        })
    }, [min, max, numberOfQuestions, secondsToAnswer, operation, props])

    function isInt(value) {
        if (isNaN(value)) {
          return false;
        }
        var x = parseFloat(value);
        return (x | 0) === x;
      }
    

    return (
            <Row>
                <Col xs="12" md={{ size: 4, offset: 4 }} >
                    <FormFieldWrap id='min'
                            innerLabelText='Min'>
                        <input id='min'
                            onChange={(e) =>{ if(isInt(e.target.value))  {setMin(parseInt(e.target.value, 10))}}}
                            value={min} 
                            className='form-control'/>
                    </FormFieldWrap>
                </Col>
                <Col xs="12" md={{ size: 4, offset: 4 }} >
                    <FormFieldWrap id='max'
                        innerLabelText='Max'>
                        <input id='max'
                            onChange={(e) => {if(isInt(e.target.value))  {setMax(parseInt(e.target.value, 10))}}}
                            value={max}
                            className='form-control' />
                    </FormFieldWrap>
                </Col>
                <Col xs="12" md={{ size: 4, offset: 4 }} >
                    <FormFieldWrap id='numberOfQuestions'
                        innerLabelText='Number Of Questions'>
                        <input id='numberOfQuestions'
                            onChange={(e) => {if(isInt(e.target.value))  {setNumberOfQuestions(parseInt(e.target.value, 10))}}}
                            value={numberOfQuestions}
                            className='form-control' />
                    </FormFieldWrap>
                </Col>
                <Col xs="12" md={{ size: 4, offset: 4 }} >
                    <FormFieldWrap id='secondsToAnswer'
                        innerLabelText='Seconds To Answer'>
                        <input id='secondsToAnswer'
                            onChange={(e) => {if(isInt(e.target.value))  {setSecondsToAnswer(parseInt(e.target.value, 10))}}}
                            value={secondsToAnswer}
                            className='form-control' />
                    </FormFieldWrap>
                </Col>
            </Row>
    )
};

export default ArithmeticAdminSignForm;
