import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import ArithmeticAdminSignForm from './ArithmeticAdminSignForm';
import { saveQuizAdmin } from '../systemUtilities/fetchUtilities';

const ArithmeticAdmin = ({ adminObject, setAdminObject }) => {

  const [activeTab, setActiveTab] = useState('+');
  const [arithmeticAdminObject, setArithmeticAdminObject] = useState(adminObject);

  const handleFormSubmit = (e) => {
    e.preventDefault();
  }

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const handleChange = (changedSign) => {

    if (arithmeticAdminObject.operationOptions === null || arithmeticAdminObject.operationOptions.length === 0) {
      arithmeticAdminObject.operationOptions = [changedSign];
      setAdminObject(arithmeticAdminObject);
      return;
    }

    var filteredOOperationOptions = arithmeticAdminObject.operationOptions.filter((element) => element.operation !== changedSign.operation)
    filteredOOperationOptions.push(changedSign);
    arithmeticAdminObject.operationOptions = filteredOOperationOptions;
    setArithmeticAdminObject(arithmeticAdminObject);
  }

  const getOperationOptions = (operation) => {
    let options = arithmeticAdminObject.operationOptions.find((element) => element.operation === operation) || {
      max: 11,
      min: 0,
      operation: operation,

    }
    return options;
  }

  const updateAdminObject = () => {
    saveQuizAdmin(arithmeticAdminObject);

  }

  return (
    <form onSubmit={handleFormSubmit}>
      <>
        <Row className={'mb-3'}>
          <Col xs='12' >

            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '+' })}
                  onClick={() => { toggle('+'); }}
                >
                  <i className='fas fa-plus' />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '-' })}
                  onClick={() => { toggle('-'); }}
                >
                  <i className='fas fa-minus' />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'x' })}
                  onClick={() => { toggle('x'); }}
                >
                  <i className='fas fa-times' />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '/' })}
                  onClick={() => { toggle('/'); }}
                >
                  <i className='fas fa-divide' />
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="+">
            <Row>
              <Col xs="12" >
                <ArithmeticAdminSignForm handleChange={handleChange} operationOptions={getOperationOptions("+")} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="-">
            <Row>
              <Col sm="12">
                <ArithmeticAdminSignForm handleChange={handleChange} operationOptions={getOperationOptions("-")} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="x">
            <Row>
              <Col sm="12">
                <ArithmeticAdminSignForm handleChange={handleChange} operationOptions={getOperationOptions("x")} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="/">
            <Row>
              <Col sm="12">
                <ArithmeticAdminSignForm handleChange={handleChange} operationOptions={getOperationOptions("/")} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      <Row>
        <Col sm={{ size: 4, offset: 4 }}>
          <Button className={'btn-block'} onClick={updateAdminObject}>Update</Button>
        </Col>
      </Row>
    </>
    </form>
    )
};

export default ArithmeticAdmin;

