import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';
import ProcessingSpinner from '../Shared/ProcessingSpinner';
import { getWatchListIfMatched } from '../systemUtilities/fetchUtilities';


const WatchListMatchedIndex = () => {
  const [matchedWatchItems, setMatchedWatchItems] = useState();
  const [url] = useState(useRouteMatch());

  useEffect(() => {
    if (!matchedWatchItems) {
      getWatchListIfMatched().then((data) => { debugger; setMatchedWatchItems(data) })
    }
  }, [matchedWatchItems]);


  return (
    <>
      { matchedWatchItems &&
        <Row>
          {matchedWatchItems.map((element) => {
            return (
              <Col key={element.id}>
                <Link to={`${url.url}Match/${element.id}`}>{element.name}</Link>
              </Col>
            );
          })}
        </Row>}
      {matchedWatchItems === undefined && <ProcessingSpinner />}
      {(matchedWatchItems !== undefined && matchedWatchItems.length === 0 ) &&  <Col><Alert color="info">No Matches</Alert></Col>}
    </>
  )
}

export default WatchListMatchedIndex;

