import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import ProcessingSpinner from '../Shared/ProcessingSpinner';
import { getWatchList } from '../systemUtilities/fetchUtilities';
import { WatchItemInput } from './WatchItemInput';
import WatchListModel from './WatchListModel'


const WatchListIndex = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalOperation, setModalOperation] = useState();
  const [watchItemModal, setWatchItemModal] = useState();
  const [watchList, setWatchList] = useState();



  useEffect(() => {
    if (!watchList) {
      getWatchList().then((data) => { debugger; setWatchList(data) })
    }
  }, [watchList]);

  const toggle = () => setShowModal(!showModal);

  return (
    <>
      <Row>
        {(watchList && watchList.length > 0) && watchList.map((element) => {
          return (
            <Col key={element.id + element.name} md={6} className='my-2'>
              <WatchItemInput
                setModal={setShowModal} 
                setModalOperation={setModalOperation} 
                setModalItem={setWatchItemModal} 
                element={element}
                afterSuccessfulDelete={() => setWatchList(undefined)}
              />
            </Col>
          )
        })}
        {watchList === undefined && <ProcessingSpinner />}
      </Row>

      <Row className='my-3'>
        <Col >
          <Button color="primary" onClick={toggle}>Add Watch Item</Button>
        </Col>
      </Row>
      <WatchListModel toggle={toggle} operation={modalOperation} isOpen={showModal} {...watchItemModal} afterSuccessfulFormSubmit={() => setWatchList(undefined)} />
    </>
  )
};

export default WatchListIndex;

