import React, { useState, Fragment, useEffect, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { speak } from '../systemUtilities/SpeechSyntesisUtilities';


//const RetypeWrongWord = (props) => {
    const RetypeWrongWord = ({word, voice, correctnessSoundPlayingStatus, onComplete}) => {

    const [retype1, setRetype1] = useState("");
    const [retype2, setRetype2] = useState("");
    const [retype3, setRetype3] = useState("");
    const answer1Ref = useRef(null);

    useEffect(() => {

        if (correctnessSoundPlayingStatus === "STOPPED") {
            speak("Please type " + word + " 3 times", voice, null)
        }
    }, [word, correctnessSoundPlayingStatus, voice])



    useEffect(() => {
        if (retype1.toUpperCase() === word.toUpperCase() && retype2.toUpperCase() === word.toUpperCase() && retype3.toUpperCase() === word.toUpperCase()) {
            onComplete();
        }
    }, [retype1, retype2, retype3, onComplete, word ])

    const sayWord = () => {
        speak(word, voice, null);
    }

    useEffect(() => {
        answer1Ref.current.focus();
    }, []);


    return (
        <Fragment>
            <Row>
                <Col xs={12} className='hugeFont text-center'>
                    <p>{word}</p>
                </Col>
                <Col xs={12}>
                    <input id={"Answer1"} className={(word.toUpperCase().includes(retype1.toUpperCase()) || retype1 === '') ? 'form-control' : 'form-control invalidInput'}
                        onChange={(e) => setRetype1(e.target.value.replace(/[^a-zA-Z]+/g, ''))}
                        value={retype1} onFocus={sayWord}
                        ref={answer1Ref}
                    />
                </Col>
                <Col xs={12} className='my-4'>
                    <input id={"Answer2"} className={(word.toUpperCase().includes(retype2.toUpperCase()) || retype2 === '') ? 'form-control' : 'form-control invalidInput'}
                        onChange={(e) => setRetype2(e.target.value.replace(/[^a-zA-Z]+/g, ''))}
                        value={retype2} onFocus={sayWord}
                    />
                </Col>
                <Col xs={12}>
                    <input id={"Answer3"} className={(word.toUpperCase().includes(retype3.toUpperCase()) || retype3 === '') ? 'form-control' : 'form-control invalidInput'}
                        onChange={(e) => setRetype3(e.target.value.replace(/[^a-zA-Z]+/g, ''))}
                        value={retype3} onFocus={sayWord}
                    />
                </Col>
            </Row>
        </Fragment>
    )
};

export default RetypeWrongWord;
