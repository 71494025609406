import React, { useState, Fragment, useEffect } from 'react';
import ParticipantWell from './Participants/ParticipantWell';
import ContestWell from './Contests/ContestWell';
import { Row, Col, Button } from 'reactstrap';
import * as yup from 'yup';
import { postNewTicketEvent } from '../../components/systemUtilities/fetchUtilities';
import ProcessingSpinner from '../Shared/ProcessingSpinner';
import ErrorMessage from '../Shared/ErrorMessage';
import { getSessionPreCreationTicketEventValues, setSessionPreCreationTicketEventValues, removeSessionPreCreationTicketEventValues } from '../systemUtilities/sessionStorageUtilities'

const Create = (props) => {

    const getDefaultTicketEvent = () => {
        // return {
        //     "participants": [],
        //     "contests": []
        // }

       return   {
            "participants": [
              {
                "email": "petemurphydbq@gmail.com",
                "name": "Peter Murphy "
              },
              {
                "email": "AudraMurphydbq@gmail.com",
                "name": "Audra Murphy"
              }
            ],
            "contests": [
              {
                "name": "4 way contest",
                "outcomes": [
                  "out1",
                  "out 2 ",
                  "out 3",
                  "out 4"
                ]
              },
              {
                "name": "contest 2",
                "outcomes": [
                  "out 2 1",
                  "out 2 2"
                ]
              }
            ]
          }
    }

    const [ticketEvent, setTicketEvent] = useState(() => { return getSessionPreCreationTicketEventValues() || getDefaultTicketEvent() });
    const [participantError, setParticipantError] = useState();
    const [contestError, setContestError] = useState();
    const [pageError, setPageError] = useState("");
    const [pageStatus, setPageStatus] = useState("idle");

    useEffect(() => {
        if (ticketEvent === undefined || ticketEvent == null) {
            return;
        }
        setSessionPreCreationTicketEventValues(ticketEvent);
    }, [ticketEvent]);

    const updateParticipants = (updatedPaticipants) => {
        setTicketEvent((ticketEvent) => { ticketEvent.participants = updateParticipants; return { participants: updatedPaticipants, contests: ticketEvent.contests }; });
    }

    const updateContests = (updatedContests) => {
        setTicketEvent((ticketEvent) => { ticketEvent.contests = updatedContests; return { participants: ticketEvent.participants, contests: updatedContests }; });
    }

    let validationSchema = yup.object().shape({
        participants: yup.array().min(2, () => setParticipantError('At least 2 particpants required')),
        contests: yup.array().min(2, () => setContestError('Required 2'))
    });


    const handleCreateEventClick = () => {
        setPageError("");
        setPageStatus("creatingTickets");
        validateTicketEvent()
            .then(createTicketEvent)
            .catch(handleErrors)
    }


    const validateTicketEvent = () => {
        setParticipantError('');
        setContestError('');

        return validationSchema.isValid(ticketEvent)
            .then((valid) => {
                if (valid) {
                    return Promise.resolve();
                } else {
                    return Promise.reject("Invalid form");
                }
            })
    }

    const createTicketEvent = () => {
        
        var test = ticketEvent;
        debugger;
        return postNewTicketEvent(ticketEvent)
            .then((response) => {
                removeSessionPreCreationTicketEventValues();
                props.history.push('/TicketMaker/Event/' + response.data)
            })
            .catch((error) => Promise.reject(error));
    }

    const handleErrors = (error) => {
        if (error !== "Invalid form") {
            setPageError(error.message);
        }
        setPageStatus("idle");
    }

    return (
        <Fragment>
            {pageStatus === "creatingTickets" &&
                <ProcessingSpinner message="Creating Tickets" />
            }

            {pageStatus === "idle" &&
                <Fragment >
                    {pageError && <ErrorMessage errorMessage={pageError} />}
                    <ParticipantWell participants={ticketEvent.participants} updateParticipants={updateParticipants} participantError={participantError} />
                    <ContestWell contests={ticketEvent.contests} updateContests={updateContests} contestError={contestError} />
                    <Row className="my-3">
                        <Col md={{ size: 4, offset: 4 }} >
                            <Button color="primary" onClick={handleCreateEventClick} size='lg' className="btn-block" >Create Event</Button>
                        </Col>
                    </Row>
                </Fragment>
            }
        </Fragment>
    )
};

export default Create;

