import React from 'react';
import AuthorizeRoute from '../../components/api-authorization/AuthorizeRoute';
import WatchListMatchIndex from './WatchListMatchIndex';
import WatchListIndex from './WatchListIndex';
import {WatchListPath} from '../../components/Shared/Constants'; 
import MatchList from './MatchList';

const WatchItemRouter = (props) => {
    return (
        <>
            <AuthorizeRoute path={`/${WatchListPath}/`} exact={true} component={WatchListMatchIndex} />
            <AuthorizeRoute path={`/${WatchListPath}/WatchListIndex/`} exact={true} component={WatchListIndex} />
            <AuthorizeRoute path={`/${WatchListPath}/Match/:watchItemId`} exact={true} component={MatchList} />
        </>
    )
};

export default WatchItemRouter;
