import React from 'react';
import { Card, CardBody,  CardHeader,  Row, Col } from 'reactstrap';

const TicketCardDeck = ({ contestKeys, tickets, participants, showParticipants }) => {

    const getPartcipantName = (ticket) => {
        let ownerParticipant = participants.find(x => x.id === ticket.ownerParticipantId);
        return ownerParticipant.name
    }


    let getTicketClassName = (ticket) => {
        
        let classNameToReturn = 'd-flex  ticketRow my-3 '
        
        if (ticket.isBlank) {
            return classNameToReturn + 'ticketRow blank';
        }
        

        for (let i = 0; i < ticket.contestOutcomeList.length; i++) {
            let contestOutcome = ticket.contestOutcomeList[i];
            classNameToReturn = classNameToReturn + '  contestIndex-' + contestOutcome.contestIndex + 'outcomeIndex-' + contestOutcome.outcomeIndex + ' ';
        }
        return classNameToReturn;
    }
    return (
        <Row>
            {tickets.map((ticket, ticketIndex) => {
                return (
                    <Col xs='12' sm='6' lg="4" key={ticketIndex} className={getTicketClassName(ticket)}>
                        <Card className='d-flex flex-fill text-center'>
                            <CardHeader>{showParticipants ? getPartcipantName(ticket) :'\u00A0' }</CardHeader>
                            <CardBody className='d-flex flex-column flex-grow-1' >
                                {ticket.isBlank ? 
                                    contestKeys.map((contestKey, contestIndex) => {return (
                                     <div key={contestIndex}>
                                        <dl>
                                            <dt className="col-12">{contestKey.name}</dt>
                                            <dd className="col-12">Blank</dd>
                                        </dl>
                                    </div>)})
                                    
                                : 
                                ticket.contestOutcomeList && ticket.contestOutcomeList.map((outcome, outcomeIndex) => {
                                    return (
                                        <div key={outcome.ticketId + outcome.contestIndex + outcome.outcomeIndex}>
                                            <dl>
                                                <dt className="col-12">{contestKeys[outcomeIndex].name}</dt>
                                                <dd className="col-12">{outcome.name}</dd>
                                            </dl>
                                        </div>
                                    );
                                })
                                }
                                
                            </CardBody>
                        </Card>
                    </Col>
                )
            })}
        </Row>
    );

};
export default TicketCardDeck;
