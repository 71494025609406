import React from 'react';
import { Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';

export function MatchInput(props) {
    return (
        <InputGroup>
            <Input value={props.name} readOnly />
            <InputGroupAddon addonType="append">
                <Button color="danger" onClick={() => props.handleWatchClick(props.id)}>Watched</Button>
            </InputGroupAddon>
        </InputGroup>
    );
}
