import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col} from 'reactstrap';
import ConstestForm from './ContestForm';
import ConstestCard from './ConstestCard';
import ErrorMessage from '../../Shared/ErrorMessage'

const ContestWell = ({ contests, updateContests, contestErrpr }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalInEditMode, setModalInEditMode] = useState();
    const [contestIndexToEdit, setContestIndexToEdit] = useState();

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    }

    const openCreateModal = () => {
        setModalInEditMode(false)
        toggleModal();
    }

    const handleContestsUpdate = (contestToPersist, indexToEdit) => {
        if (indexToEdit === undefined) {
            updateContests([...contests, contestToPersist]);
        }
        else {
            updateContests(contests.map((contest, index) => { return index === indexToEdit ? contestToPersist : contest }));
        }
        toggleModal();
    }

    const deleteParticipant = (indexToDelete) => {
        updateContests(contests.filter((contest, index, ) => { return index !== indexToDelete; }))
    }

    return (
        <div className='create-well text-center'>

            <Row className="my-3">
                <Col sm="12">
                    <h3>Contests</h3>
                    <hr />
                </Col>
            </Row>
            {contestErrpr &&
                <ErrorMessage errorMessage={contestErrpr} />
            }

            {(contests.length > 0) && <>
                <Row className="my-3 ">
                    {contests.map((contest, contestsIndex) =>
                        <Col md="6 d-flex flex-grow-1 flex-column mb-3" key={contestsIndex}>
                            <ConstestCard {...contest}
                                handleDeleteClick={() => { deleteParticipant(contestsIndex) }}
                                handleEditClick={() => { setModalInEditMode(true); setContestIndexToEdit(contestsIndex); toggleModal(); }}
                                contestIndex={contestsIndex} />
                        </Col>
                    )}
                </Row>
            </>}

            <Row className="my-3">
                <Col md={{ size: 3,  offset: 9 }}>
                    <button className="btn btn-secondary btn-block float-right" onClick={openCreateModal} >Add Contest</button>
                </Col>
            </Row>


            <Row>
                <Modal isOpen={modalOpen} toggle={toggleModal} >
                    <ModalHeader toggle={toggleModal}>{modalInEditMode === true ? 'Edit ' : 'Create '}Contest</ModalHeader>
                    <ModalBody>
                        <ConstestForm handleValidSubmit={handleContestsUpdate}
                            contestIndex={modalInEditMode ? contestIndexToEdit : undefined}
                            {...(modalInEditMode ? contests[contestIndexToEdit] : {})}
                        />
                    </ModalBody>
                </Modal>
            </Row>
        </div>
    )
};

export default ContestWell;
