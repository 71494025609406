import React from 'react';
import { Button, Card, CardBody, CardText, CardTitle, CardHeader, ButtonGroup, Row, Col } from 'reactstrap';

const ParticipantCard = ({
    email,
    name,
    participantIndex,
    handleDeleteClick,
    handleEditClick
}) => {
    return (

        <Card className='d-flex flex-fill w-100'>
            <CardHeader>
                Participant {participantIndex + 1}
            </CardHeader>
            <CardBody className='d-flex flex-column flex-grow-1'>
            <div className='d-flex flex-grow-1 flex-column mb-3'>
            

                <CardTitle>
                    {name}
                </CardTitle>
                <CardText >
                    {email}
                </CardText>
                </div>
                <Row>
                    <Col sm="12">
                        <ButtonGroup className="special">
                            <Button onClick={handleEditClick} outline color="info">Edit</Button>
                            <Button onClick={handleDeleteClick} outline color="danger">Remove</Button>
                        </ButtonGroup>
                    </Col>

                </Row>
            </CardBody>
        </Card>
    );
}
export default ParticipantCard;