import {setSessionOutcomeFilters, getSessionOutcomeFilters} from '../systemUtilities/sessionStorageUtilities';

export function applyOutcomeFilterToTicketDisplay(outcomeFilters) {

    let allTicketRows = document.querySelectorAll('.ticketRow')
    for (let i = 0; i < allTicketRows.length; i++) {
        allTicketRows[i].classList.add('d-none');
    }

    let filterDetected = false;
    let filterClassSelectorString = '';
    for (let contestIndex = 0; contestIndex < outcomeFilters.length; contestIndex++) {
        for (let outcomeIndex = 0; outcomeIndex < outcomeFilters[contestIndex].length; outcomeIndex++) {
            if (outcomeFilters[contestIndex][outcomeIndex]) {
                filterDetected = true;
                filterClassSelectorString = filterClassSelectorString + '.contestIndex-' + contestIndex + 'outcomeIndex-' + outcomeIndex
            }
        }
    }

    if (filterDetected) {
        for (let i = 0; i < allTicketRows.length; i++) {
            allTicketRows[i].classList.add('d-none');
        }
    } else {
        for (let i = 0; i < allTicketRows.length; i++) {
            allTicketRows[i].classList.remove('d-none');
        }
    }

    if (filterClassSelectorString) {
        var ticketsToUnhide = document.querySelectorAll(filterClassSelectorString);
        for (let i = 0; i < ticketsToUnhide.length; i++) {
            ticketsToUnhide[i].classList.remove('d-none');
        }
    }


    if (document.querySelectorAll(".ticketRow:not(.d-none)").length === 0) {
        document.querySelector('#NoTicketsLeftWarning').classList.remove('d-none');
    }
    else {
        document.querySelector('#NoTicketsLeftWarning').classList.add('d-none');
    }
}

export function updateFilters(contestIndex, outcomeIndex, outcomeFilters, ticketEventId)  {
    var newContestFilterArray = outcomeFilters.map(function (arr) {
        return arr.slice();
    });

    //set all outcomes for that contest index to false bc 1 filter only at a time per contest
    for (let i = 0; i < newContestFilterArray[contestIndex].length; i++) {
        newContestFilterArray[contestIndex][i] = false;
    }

    newContestFilterArray[contestIndex][outcomeIndex] = !outcomeFilters[contestIndex][outcomeIndex];
    setSessionOutcomeFilters(newContestFilterArray, ticketEventId);
    return newContestFilterArray;
}

export function resetAllContestFilters(contestIndex, outcomeFilters, ticketEventId ){
        
    var newContestFilterArray = outcomeFilters.map(function (arr) {
        return arr.slice();
    });

    //reset all contest to none
    for (let i = 0; i < newContestFilterArray[contestIndex].length; i++) {
        newContestFilterArray[contestIndex][i] = false;
    }

    setSessionOutcomeFilters(newContestFilterArray, ticketEventId);
    return newContestFilterArray;
}

export function getOrCreateOutcomeFilters(ticketEvent){

    let seesionOutcomeFilters =  getSessionOutcomeFilters(ticketEvent.id)
    if(seesionOutcomeFilters !== null){
        return seesionOutcomeFilters;
    }

    var activeFiltersArray = [];
    for (let i = 0; i < ticketEvent.contestKeys.length; i++) {
        let outcomesForContestI = ticketEvent.outcomeKeys.filter(outcome => outcome.contestIndex === i);
        let innerOutcomeArrayFilter = []
        for (let j = 0; j < outcomesForContestI.length; j++)
            innerOutcomeArrayFilter.push(false);

        activeFiltersArray.push(innerOutcomeArrayFilter);
    }

    setSessionOutcomeFilters(activeFiltersArray, ticketEvent.id);
    return activeFiltersArray;
}