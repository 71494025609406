import {getSessionStorageTicketEvent, setSessionlStorageTicketEvent} from './sessionStorageUtilities'
import {getEventById} from './fetchUtilities'

export async function  getTicketEvent(ticketEventId) {

    let ticketEventFromSessionStorage = getSessionStorageTicketEvent(ticketEventId)
    if(ticketEventFromSessionStorage !== null)
    {
        return Promise.resolve(ticketEventFromSessionStorage);
    }
    
    return getEventById(ticketEventId)
        .then((response) => {
            if (response.successful) {
                setSessionlStorageTicketEvent(response.data);
                return Promise.resolve(response.data);
            }
        });
}


export function getparticipantIdView(ticketEventId, participantId)
{
    return getTicketEvent(ticketEventId)
        .then((ticketEvent) => {
            ticketEvent.participants = ticketEvent.participants.filter((participant) => {return participant.id === participantId})
            ticketEvent.tickets = ticketEvent.tickets.filter((ticket)=> {return ticket.ownerParticipantId === participantId})
            return ticketEvent;
        })
}





