import React from 'react';
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap';
import {deleteWatchItem} from '../systemUtilities/fetchUtilities' 

export function WatchItemInput(props) {

    const handleUpdateClick = () =>{
        props.setModal(true);
        props.setModalItem(props.element);
        props.setModalOperation("Update");
    }

    const handleDeleteClick = () =>
    {
        deleteWatchItem(props.element)
        .then(props.afterSuccessfulDelete);
    }

    return (
        <InputGroup>
            <Input value={props.element.name} readOnly />
            <InputGroupAddon addonType="append">
                <Button color="secondary" onClick={handleUpdateClick}>Update</Button>
                <Button color="danger" onClick={handleDeleteClick}>Delete</Button>
            </InputGroupAddon>
        </InputGroup>
    );
}
