import React from 'react';
import { Route } from 'react-router';
import  Create  from './Create';
import TicketEventParticpantView from'./TicketEventParticpantView';
import  TicketEventFullView from './TicketEventFullView';
import { TicketMakerPath} from '../Shared/Constants';


const TicketMakerApp = (props) => {

    return (
        <>
            <Route exact path={`/${TicketMakerPath}/`} component={Create} />
            <Route path={`/${TicketMakerPath}/Event/:id`} component={TicketEventFullView} />
            <Route path={`/${TicketMakerPath}/ParticpantView/:eventId/:particpantId`} component={TicketEventParticpantView} />
        </>
    )
};

export default TicketMakerApp;
