import React, { useState, useEffect, Fragment } from 'react';
import { getparticipantIdView } from '../systemUtilities/ticketEventDataInterface'
import TicketFilterUI from './TicketFilterUI';
import { applyOutcomeFilterToTicketDisplay, updateFilters, resetAllContestFilters, getOrCreateOutcomeFilters } from '../systemUtilities/filterUtilities';
import TicketDisplay from './TicketDisplay';
import ProcessingSpinner from '../Shared/ProcessingSpinner';
import ErrorMessage from '../Shared/ErrorMessage';

const TicketEventParticpantView = (props) => {

    const [ticketEvent, setTicketEvent] = useState();
    const [outcomeFilters, setOutcomeFilters] = useState();
    const [pageError, setPageError] = useState("");
    const [pageStatus, setPageStatus] = useState("idle");

    let handleUpdateFiltersClick = (contestIndex, outcomeIndex) => {
        var updatedOutcomeFilters = updateFilters(contestIndex, outcomeIndex, outcomeFilters, ticketEvent.id);
        setOutcomeFilters(updatedOutcomeFilters);
    }

    let handleResetAllContestFiltersClick = (contestIndex) => {
        var updatedOutcomeFilters = resetAllContestFilters(contestIndex, outcomeFilters, ticketEvent.id)
        setOutcomeFilters(updatedOutcomeFilters);
    }

    useEffect(() => {
        if (outcomeFilters === undefined || pageStatus !== 'idle') {
            return;
        }
        applyOutcomeFilterToTicketDisplay(outcomeFilters);
    }, [outcomeFilters, pageStatus]);

    useEffect(() => {
        setPageStatus("Getting Ticket Event");
        getparticipantIdView(props.match.params.eventId, props.match.params.particpantId)
            .then((ticketEvent) => {
                setTicketEvent(ticketEvent);
                setOutcomeFilters(getOrCreateOutcomeFilters(ticketEvent));
                setPageStatus("idle");
            }).catch((error) => {
                setPageError(error.message);
                setPageStatus("idle");
            });

    }, [props.match.params.particpantId, props.match.params.eventId]);

    
    return (
        <div>
            {pageError && <ErrorMessage errorMessage={pageError} />}
            {pageStatus === "Getting Ticket Event" &&
                <ProcessingSpinner message="Loading Tickets" />
            }
            {(pageStatus === "idle" && ticketEvent !== undefined) &&
                <Fragment>
                    <TicketFilterUI contestKeys={ticketEvent.contestKeys}
                        outcomeKeys={ticketEvent.outcomeKeys}
                        outcomeFilters={outcomeFilters}
                        updateFilters={handleUpdateFiltersClick}
                        resetAllContestFilters={handleResetAllContestFiltersClick}
                        
                    />
                    <TicketDisplay ticketEvent={ticketEvent}
                                   showParticipants={false} 
                                   particpantName={ticketEvent.participants[0].name}
                     />
                </Fragment>
            }
        </div>
    )
};

export default TicketEventParticpantView;

