import React, { useState, useEffect } from 'react';
import { Route } from 'react-router';
import SpellQuiz from './SpellQuiz';
import {browserSupported} from '../systemUtilities/SpeechSyntesisUtilities';
import ProcessingSpinner from '../Shared/ProcessingSpinner';
import { Col, Row, Button } from 'reactstrap';
import  BrowserNotCompatiable from './BrowserNotCompatiable';
import {SpellingQuizPath} from '../Shared/Constants';

const SpellingQuizRouter = (props) => {

    const [voicesLoaded, setVoicesLoaded] = useState(false);
    const [voice, setVoice] = useState(undefined);
    const [startButtonClicked, setStartButtonClicked] = useState(false);
    const [browserHasSpeechAPI, setBrowserHasSpeechAPI] = useState(undefined);

    const tryToLoadVoices = (voices) => {
        const voice = voices.find(voice => voice.name === 'Google US English');
        setVoice(voice !== undefined ? voice : voices[0]);
    }

    const allVoicesObtained = new Promise(function (resolve, reject) {
        let voices = window.speechSynthesis.getVoices();
        if (voices.length !== 0) {
            resolve(voices);
        } else {
            window.speechSynthesis.addEventListener("voiceschanged", function () {
                voices = window.speechSynthesis.getVoices();
                resolve(voices);
            });
        }
    });

    useEffect(() => {
        setBrowserHasSpeechAPI(browserSupported())
        allVoicesObtained.then(tryToLoadVoices).then(() => setVoicesLoaded(true));
    }, [allVoicesObtained])

    useEffect(() => {
        if (browserHasSpeechAPI) {
            allVoicesObtained.then(tryToLoadVoices).then(() => setVoicesLoaded(true));
        }
    }, [browserHasSpeechAPI, allVoicesObtained])

    return (
            <>
                {!browserHasSpeechAPI && <BrowserNotCompatiable/>}
                {(browserHasSpeechAPI && !voicesLoaded) && <ProcessingSpinner message={"Loading Voices"} />}
                {(!startButtonClicked && voicesLoaded) && <Row className=''><Col className='d-flex justify-content-center'><Button onClick={() => setStartButtonClicked(true)}>Start Quiz</Button></Col></Row>}
                {(startButtonClicked && voicesLoaded)&&
                    <>
                        <Route path={`/${SpellingQuizPath}/:unitId`} exact={true} render={(props) => <SpellQuiz {...props} voice={voice} />} />
                        <Route path={`/${SpellingQuizPath}/`} exact={true} render={(props) => <SpellQuiz {...props} voice={voice} />} />
                    </>}
            </>
    )
};

export default SpellingQuizRouter;

